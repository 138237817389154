(function () {
    angular.module('aerosApp')
        .directive('aflLinkmapEventSection', aflLinkmapEventSection);

    function aflLinkmapEventSection() {
        return {
            restrict: 'E',
            scope: {
                event: '=',
                imageMap: '=',
                iconMap: '=',
                groupSets: '=',
                groupRows:'=',
                unit: '=',
                lossDistUnit: '='
            },
            controller: aflLinkmapEventSectionController,
            templateUrl: '/static/javascript/directives/afl-linkmap/afl-linkmap-event-section.html'
        }
    }

    aflLinkmapEventSectionController.$inject = ['$scope', '$sce', 'UnitsMeasurementService', 'LinkmapService'];

    function aflLinkmapEventSectionController($scope, $sce, UnitsMeasurementService, LinkmapService) {

        angular.extend($scope, {
            getLocationPrecision: LinkmapService.getLocationPrecision,
            getPrecision: getPrecision
        });

        function getPrecision(field, value, event) {
            if (typeof value === 'undefined'
                || (value == 0 && field.name == 'Loss(dB)'
                    && (event.eventType == 'OpenEnd' || event.eventType == 'End'))) {
                return '--';
            }

            switch (field.name) {
                case 'Loss(dB)': {
                    return $sce.trustAsHtml(
                        (event.isMTP ? "&asymp;&nbsp;":"")
                        + value.toFixed(3));
                }
                case 'Refl(dB)': {
                    return value.toFixed(2);
                }
                case 'Cum Loss(dB)': {
                    return value.toFixed(3);
                }
                case 'Atten(' + ($scope.lossDistUnit || 'dB/km') + ')':
                    if ($scope.lossDistUnit !== 'dB/km') {
                        var converters = UnitsMeasurementService.getConverters('dB/km|' + $scope.lossDistUnit);
                        if (converters.showUnitConverter) {
                            return converters.showUnitConverter(value).toFixed(3);
                        }
                    }
                    return value.toFixed(3);
                default: return value;
            }
        }
    }


})();
